import * as React from 'react'
import Layout from '../components/layout'
import PartnershipsTabs from '../components/partnershipsTabs'
import { StaticImage } from 'gatsby-plugin-image'
import {
  content,  
  cover,
  container,
} from '../components/layout.module.css'

const Approach = () => {
    return (
      <Layout pageTitle="Our Approach">
  
        <div className={cover}>
          <StaticImage src="../images/keyelements.jpg" alt="" style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            filter: "brightness(0.5)",
          }}
          loading="eager"
          layout="fullWidth"></StaticImage>
          <div className={container +' '+ content}>
            <h1>Partnerships</h1>
          </div>
        </div>

        <div className={container}>
        <h2>Key Elements of a Successful Partnership</h2>
        </div>
        
        <div className={container}>
            <PartnershipsTabs></PartnershipsTabs>
        </div>
    
    </Layout>
  )
}

export default Approach